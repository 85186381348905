export const AlertErrorMessageTemplate =
  'エラーが発生したため処理を完了できませんでした。'

export const OfflineErrorMessage =
  'オフラインのため処理が完了できませんでした。'

export const Message = {
  FORGOT_PASSWORD_DESCRIPTION:
    '以下のフォームに登録したメールアドレスをご入力いただけますと、\nパスワード再設定メールをお送りします。',
  FORGOT_PASSWORD_SUCCESS:
    '送信が完了しました。\nメールが届くまで少々お待ちください。',
  RESET_PASSWORD_DESCRIPTION:
    'パスワードをリセットします。\n以下のフォームに新しいパスワードを入力してください。',
  COVER_IMAGE_DESCRIPTION: `カバー画像は、「募集ポジションから探す」にて用いられます。\n職場やチームの雰囲気がわかる画像を設定しましょう!`,
  COVER_IMAGE_CAUTION: `※ 多少のズレが生じる可能性がありますのでご了承ください。\n※ この画像の上に少し暗めの影がかかり、各種情報がテキストで掲載されます。`,
  USER_CRUD_REQUEST_CAUTION: `※ セキュリティチェック等の処理のため、通常のリクエストよりも\n少々時間がかかります。ご了承ください。`,
  AUTHORITY_ADMIN_DESCRIPTION: `管理者はメンバーの追加や削除、権限の変更を行うことができます。\nまた、管理者には「選考に関する重要なリマインド」等をメールでお送りします。`,
  MESSAGE_NOTIFICATION_DESCRIPTION: `候補者からの未読メッセージがあった場合に、メールで通知することができます。管理画面でメッセージを利用される方は、通知をONにしておくことをオススメいたします。`,
  CANNOT_CHANGE_AUTHORITY_DESCRIPTION: `※ 管理者は最低でも1ユーザ必要なため、現在は変更できません。`,
  RESCHEDULE_TEMPLATE_MESSAGE: `すみませんが、以下の日程候補で調整をお願いできませんか？`,
  HELPFUL_ACE_CONTENT_MESSAGE: ``, // 各フロントアプリケーションで生成するので空文字
  NP_DESCRIPTION: `法人/個人事業主を対象とした掛け払いサービスです。 (月末締め翌月末請求書払い) \n請求書は、株式会社ネットプロテクションズからご購入の翌月第1営業日に発行されます。\n与信枠は、個別に設定させていただきます。\n請求書に記載されている銀行口座または、コンビニの払込票でお支払いください。\n※銀行振込の際の振込手数料はお客様にてご負担ください。\n請求代行のため代金債権は同社へ譲渡されます。`,
  CANNOT_BUY_TICKETS_BY_LEACH_LIMIT: `今月のチケット購入金額が上限に達しているため、来月になるまでチケットは購入できません。`,
  CANNOT_BUY_TICKETS_BY_REQUESTING: `現在審査中のチケットがあるため、チケットは購入できません。`,
  SENDING_ACE_CAN_CHOOSE_WHO:
    '送付エースは、誰からオファーを送るか選ぶことができます。\n候補者に合わせたオファー送付が可能です。',

  NOT_SELECTED_ACE:
    '画面上部より、送付エースを選択してください。 \n送付エースは、エース一覧で登録された方が表示されます。 \n候補者に合わせたオファー送付が可能です。',
  NOTIFICATION_SKILL_SEARCH:
    '半角スペース区切りで最大5つまで指定可能です。\nEnterキーで検索が実行されます。',
  UNTIED_POSITION:
    'オファーを送るためには、送付エースに \n募集ポジションが紐付けする必要があります。 \n「エース一覧」の該当エースの募集ポジションより \n「掲載を開始する」をクリックして、紐付けしましょう。 \n募集ポジションは「募集ポジション」より \n新規に作成することができます。',
  SAME_ACE_TO_OFFER:
    '同じ方から繰り返し候補者にオファーを送ることはできません。\n別の送付エースを選択して、オファーをお送りください',
  RELATING_ACE:
    'エースを関連つける、「この記事に関連するメンバー」として表示されます。\nまた関連づけたエースからオファーを送付する際、記事も合わせて候補者に届けることができます。',
  LETS_GET_OFFER_COUNT:
    '1記事公開ごとに10通 \n毎月最大20通オファーを \nGETしよう!',
  POST_SHORT_INCREASE_OFFER_TO_TEN_OFFER_COUNT:
    'ショートを掲載してオファーを増やそう \n1記事書くと10通獲得できます',
  POST_SHORT_INCREASE_OFFER: 'ショートを掲載して オファーを増やそう。',
  NOT_CREATED_SHORT: 'まだショートが作成されていません。',
  SEND_OFFERS_BASED_ON_POSTINGS:
    '早速、募集ポジションを掲載して、\n気になる候補者に、オファー送りましょう！',
  DISPLAY_FREE_LIKES_OFFERS:
    '募集ポジションやエースに「気になる」が届くとこちらに表示されます。\n気になるが届いた相手とは、オファー数の消費なくマッチングできるので、\nたくさんの「気になる」を集めましょう！',

  TOOLTIP_OFFER_COUNT_MONTHLY:
    '毎月付与されるオファー数です。優先的に消費されます。\n順次案内している契約更新後、付与されるようになります。',
  TOOLTIP_OFFER_COUNT_SHORT:
    'ショートを掲載して獲得したオファー数です。\nうち毎月分の次に優先して消費されます。',
  TOOLTIP_OFFER_COUNT_BONUS:
    '毎月分とショート分以外のオファー数です。\n2023年11月以前の購入分のオファーも含まれます。',
  TOOLTIP_RECRUITMENT_HELP:
    '募集ポジションは「エース一覧」よりエースに紐づけることで公開されます。',
  MESSAGE_CHAT_FIRST_MESSAGE: `マッチングおめでとうございます！\n早速メッセージのやり取りをしましょう！`,
}

export const ValidationMessage = {
  APPEAL_OF_WORKING_MIN: '20字以上記載してください',
  APPEAL_OF_WORKING_MAX: '400字を超えています',
  BUSINESS_DETAIL_MIN: '20字以上記載してください',
  BUSINESS_DETAIL_FOR_AGENT_MAX: '1200字を超えています',
  BUSINESS_DETAIL_MAX: '400字を超えています',
  DATE_FORMAT: '日付の書式が不正です',
  INTRODUCTION_MAX: '1200字を超えています',
  INTRODUCTION_MIN: '20字以上記載してください',
  INVALID_FORMAT_EMAIL: 'メールアドレスの形式が不正です',
  JOIN_AT_COMPARE: '本日以降の日付を入力してください',
  MIN_PASSWORD: 'パスワードは6文字以上入力です',
  NOT_MATCH_PASSWORD: 'パスワードが一致していません',
  TEL_NUMBER_OF_DIGITS: '電話番号は数字で10〜13桁のものをご入力ください',
  POSTAL_CODE_NUMBER_OF_DIGITS: '郵便番号の形式が不正です',
  RECRUITMENT_DESCRIPTION_MAX: '1200字を超えています',
  RECRUITMENT_DESCRIPTION_MIN: '30字以上記載してください',
  RECRUITMENT_TITLE_MAX: '60字を超えています',
  RECRUITMENT_TITLE_MIN: '10字以上記載してください',
  RECRUITMENT_WANT_MAX: '1200字を超えています',
  RECRUITMENT_WANT_MIN: '30字以上記載してください',
  REQUIRED: 'この項目は入力必須です',
  VALUE_IN_JOB_MAX: '400字を超えています',
  VALUE_IN_JOB_MIN: '20字以上記載してください',
  WORKING_DURATION_COMPARE: '開始時期が終了時期を超えています',
  MIN_SKILL_COUNT: 'スキル数は５つ以内で検索してください',
  SEARCH_SKILL_WHITE_SPACE:
    '複数検索は単語毎に半角スペース１つで区切ってください',
  PERSONALITY_MAX: '400字を超えています',
  URL: '不正なURLです',
}

export const FirebaseErrorMessage = {
  'auth/invalid-email': '無効なメールアドレスです。',
  'auth/expired-action-code':
    'リクエストの有効期限が切れています。\n再度パスワードのリセットをリクエストしてください。',
  'auth/invalid-action-code':
    '無効なコードのためパスワードをリセットすることができません。\n再度パスワードのリセットをリクエストしてください。',
  'auth/user-disabled': '更新対象のユーザは現在利用不可のため更新できません。',
  'auth/user-not-found': '該当のメールアドレスは登録されていません。',
  'auth/weak-password': 'パスワードの強度が弱いため登録できません。',
  'auth/wrong-password': 'パスワードが間違っています。',
  unknown: '予期せぬエラーが発生しました。\n開発者にお問い合わせください。',
}

export const SuccessMessage = {
  CREATE: '作成しました！',
  APPLY: '申し込みが完了しました！',
  SAVE: '保存しました！',
}
