import { toast } from 'react-toastify'
import AppError from 'utils/AppError'
import { AlertErrorMessageTemplate, SuccessMessage } from 'constants/Message'

export const showSuccessToast = (
  message?: string | null,
  callback?: () => void,
) => {
  toast(message || SuccessMessage.SAVE, {
    type: 'success',
    autoClose: 1500,
    closeButton: false,
    hideProgressBar: true,
  })

  if (callback) {
    callback()
  }
}

export const showFailureToast = (error: Error, message?: string) => {
  let errorMessage = null
  console.error(error)
  if (error instanceof AppError) {
    errorMessage = error.message
  }
  if (message) {
    errorMessage = message
  }
  toast.error(errorMessage || AlertErrorMessageTemplate, {
    autoClose: 5000,
    hideProgressBar: true,
  })
}
